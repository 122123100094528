@font-face {
  font-family: A_Waheed;
  src: url(./assets/fonts/A_Waheed.otf);
}

@font-face {
  font-family: Faruma;
  src: url(./assets/fonts/Faruma.otf);
}

@font-face {
  font-family: MV_Faseyha;
  src: url(./assets/fonts/MV_Faseyha.otf);
}

@font-face {
  font-family: Calibri;
  src: url(./assets/fonts/Calibri.otf);
}

@font-face {
  font-family: Montserrat;
  src: url(./assets/fonts/Montserrat.otf);
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.dispenser label,
.token-attach-modal label {
  color: black;
  align-self: center;
  margin-bottom: 0;
  font-weight: 500;
  text-align: left;
}

.ka-table colgroup {
  border: 1px solid transparent;
}

.ka-table-wrapper {
  /* min-width:1500px; */
  /* overflow: hidden !important; */
}

.approval-table .ka-table {
  min-width: 1500px;
  /* overflow: hidden !important; */
}

.cursor-pointer:hover,
.pointer-hover:hover {
  cursor: pointer;
}

.collapse i::before,
.collapsing i::before {
  vertical-align: middle;
}

.toggle-next+div {
  display: none;
}

.toggle-next:checked+div {
  display: flex;
  align-items: center;
  background: #80808054;
}

body>iframe[style*="2147483647"] {
  display: none;
}

.modal-header {
  justify-content: space-between !important;
}

.modal-header .close {
  margin-left: 0;
}

.modal-header h5 {
  margin-bottom: 0;
}