*::-webkit-scrollbar {
    width: 0.5em;
}
 
/* *::-webkit-scrollbar-track { */
    /* background:transparent; */
/* } */
 
*::-webkit-scrollbar-thumb {
  background-color: rgba(169, 169, 169, 0.75);
  /* outline: 1px solid slategrey; */
    border-radius:1rem;
    width:50%;
}

#display *::-webkit-resizer {
    cursor:se-resize;
}

#display *::-webkit-scrollbar {
    width: 0.5em;
}
 
/* #display *::-webkit-scrollbar-track { */
    /* background:transparent; */
/* } */
 
#display *::-webkit-scrollbar-thumb {
  background-color: rgba(169, 169, 169, 0.75);
  /* outline: 1px solid slategrey; */
    border-radius:1rem;
    /* width:50%; */
    width:50%;
}

.hov,.col-div{
    width:50px;
    opacity:0.001;
}
.hov:hover,.col-div:hover{
    opacity:0.6;
    background:rgba(184, 184, 184, 0.411);
    z-index:1000;
}

.slidVid{
    display:none;
}
.slidImg{
    opacity:0;
    position:absolute;
    width:100%;
}









.imgOn{
    z-index:4;
    opacity:1;
    animation:appear 0.4s 1;
}
@keyframes appear{
    from{
        opacity:0
    }
    to{
        opacity:1
    }
}
.imgOff{
    /* opacity:0; */
    animation:disappear 0.4s 1;
}
@keyframes disappear{
    from{
        opacity:1
    }
    to{
        opacity:0
    }
}




.imgRight-on{
    opacity:1;
    z-index:4;
    transform:translate(0%);
    animation:rightn 0.4s 1;
}
@keyframes rightn{
    from{
        transform:translate(-100%);
    }
    to{
        transform:translate(0%);
    }
}

.imgRight-off{
    opacity:1;
    transform:translate(100%);
    animation:rightf 0.4s 1;
}
@keyframes rightf{
    from{
        transform:translate(0%);
    }
    to{
        transform:translate(100%);
    }
}




.imgLeft-on{
    opacity:1;
    z-index:4;
    transform:translate(0%);
    animation:leftn 0.4s 1;
}
@keyframes leftn{
    from{
        transform:translate(100%);
    }
    to{
        transform:translate(0%);
    }
}

.imgLeft-off{
    opacity:1;
    transform:translate(-100%);
    animation:leftf 0.4s 1;
}
@keyframes leftf{
    from{
        transform:translate(0%);
    }
    to{
        transform:translate(-100%);
    }
}












#secIn{
    display: inline-block;
    position: relative;
}
#secIn::after{
    content:'s';
    position: absolute;
    top: 2px;
    right: 1em;
    transition: all .05s ease-in-out;
}

.pxIn{
    display: inline-block;
    position: relative;
}

.percIn{
    display: inline-block;
    position: relative;
}
.percIn::after,.pxIn::after{
    content:'%';
    position: absolute;
    top: -7px;
    right: -7px;
    transition: all .05s ease-in-out;
    background: white;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    padding:10px;
    display:flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1;
    box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0.622);
}
.pxIn::after{
    content:'PX';
    font-size: 0.75rem;
}

.col-div{
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:#4d4d4d44;
    display:flex;
    flex-flow: row;
    justify-content: center;
}
.col-div div{
    background:rgba(184, 184, 184, 0.411);
    border:5px solid transparent;
    font-weight:bold;
    padding:0.5rem;
    cursor:pointer;
    margin:auto;
}
.display .card{
    height:95%;
}

.cus-sel{
    padding:10px;
    border: 1px solid #E3E3E3;
    box-shadow: none;
    background-color: transparent;
    border-radius: 4px;
    margin:auto
}

.radio-align + img{
    border:1px solid transparent;
    border-radius: 4px;
    padding:3px;
}
.radio-align:checked + img{
    border-color:#4d4d4d;
}

.blink{
    animation:blink 1s 5;
}
@keyframes blink{
    from{
        opacity:0
    }
    to{
        opacity:1s
    }
}
.transform-none{
    transform:none !important;
}