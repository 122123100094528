.card-collapse{
  padding-bottom: 10px;
    .card{
      margin-bottom: 0px;

      .card-header{
        position: relative;
        padding: 20px 0;

          a[data-toggle="collapse"]{
            display: block;
            color: $light-black;

              i{
                float: right;
                position: relative;
                color: #f96332;
                top: 3px;
                right: 5px;
                @extend .animation-transition-general;
              }
          }

          &:after{
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: $light-gray;
          }
        }

        .card-body{
          padding: 20px 15px;
        }
    }
}
