.tim-row {
  margin-bottom: 20px;
}

.tim-white-buttons {
  background-color: #777777;
}

.typography-line {
  padding-left: 25%;
  margin-bottom: 35px;
  position: relative;
  display: block;
  width: 100%;
}

.typography-line span {
  bottom: 10px;
  color: #c0c1c2;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  left: 0;
  position: absolute;
  width: 260px;
  text-transform: none;
}

.tim-row {
  padding-top: 60px;
}

.tim-row h3 {
  margin-top: 0;
}

.offline-doc .page-header {
  display: flex;
  align-items: center;
}

.offline-doc .footer {
  position: absolute;
  width: 100%;
  background: transparent;
  bottom: 0;
  color: #fff;
  z-index: 1;
}

#map {
  position: relative;
  width: 100%;
  height: 100vh;
}

.demo-iconshtml {
  font-size: 62.5%;
}

.demo-icons body {
  font-size: 1.6rem;
  font-family: sans-serif;
  color: #333333;
  background: white;
}

.demo-icons a {
  color: #608CEE;
  text-decoration: none;
}

.demo-icons header {
  text-align: center;
  padding: 100px 0 0;
}

.demo-icons header h1 {
  font-size: 2.8rem;
}

.demo-icons header p {
  font-size: 1.4rem;
  margin-top: 1em;
}

.demo-icons header a:hover {
  text-decoration: underline;
}

.demo-icons .nc-icon {
  font-size: 34px;
}

.demo-icons section h2 {
  border-bottom: 1px solid #e2e2e2;
  padding: 0 0 1em .2em;
  margin-bottom: 1em;
}

.demo-icons ul {
  padding-left: 0;
}

.demo-icons ul::after {
  clear: both;
  content: "";
  display: table;
}

.demo-icons ul li {
  width: 20%;
  float: left;
  padding: 16px 0;
  text-align: center;
  border-radius: .25em;
  -webkit-transition: background 0.2s;
  -moz-transition: background 0.2s;
  transition: background 0.2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
}

.demo-icons ul li:hover {
  background: #f4f4f4;
}

.demo-icons ul p,
.demo-icons ul em,
.demo-icons ul input {
  display: inline-block;
  font-size: 1rem;
  color: #999999;
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.demo-icons ul p {
  padding: 20px 0 0;
  font-size: 12px;
  margin: 0;
}

.demo-icons ul p::selection,
.demo-icons ul em::selection {
  background: #608CEE;
  color: #efefef;
}

.demo-icons ul em {
  font-size: 12px;
}

.demo-icons ul em::before {
  content: '[';
}

.demo-icons ul em::after {
  content: ']';
}

.demo-icons ul input {
  text-align: center;
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none;
  display: none;
}

.login-form-answer {
  margin-left: 10px;
}

.login-form-left {
  margin-left: 10px;
}

.login-form-right {
  margin-left: 16px;
  margin-right: 10px;
}

.login-form-right .site-link-button {
  margin-top: 24px;
  margin-bottom: 36px;
}

.site-link-button {
  margin-top: 24px;
  margin-bottom: 36px;
}

.separator-vertical-line {
  background-color: #9A9A9A;
  width: 2px;
  height: 194px;
}

.activate-status {
  margin-top: 35px;
  margin-left: 29px;
}

.input-search {
  margin-top: 9px;
}

.select-location {
  margin-top: 8px;
}

.profile-row {
  margin-top: 20px;
}

.profile-nav-item {
  width: 20%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.profile-name {
  margin-top: 7px;
}

.profile-value {
  margin-top: auto;
  margin-bottom: auto;
}

.logo-normal {
  margin-left: 23px;
}

.full-height-page {
  height: 825px;
}
.top-margin-section {
  margin-top: 75px;
}
.top-margin-package-card-1 {
  margin-top: 91px;
}
.account-tab {
  margin: 10px;
}
.custom-nav {
  margin-right: 5px;
}
.custom-nav-left {
  margin-left: 50px;
}
.rt-table {
  text-align: center;
}
  .rt-resizable-header-content {
    text-align: center;
  }
.btn {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.left-margin-50 {
  margin-left: 50px;
}
.left-margin-20 {
  margin-left: 20px;
}
.left-margin-12 {
  margin-left: 12px;
}
.left-margin-10 {
  margin-left: 10px;
}
.top-margin-per-40 {
  margin-top: 45%;
}
.top-margin-per-20 {
  margin-top: 20%;
}
.top-margin-12 {
  margin-top: 15px;
}
.top-margin-10 {
  margin-top: 10px;
}
.top-margin-7 {
  margin-top: 7px;
}
.margin-top-1 {
  margin-top: 1px;
}
.margin-top-0 {
  margin-top: 0;
}
.top-margin-2 {
  margin-top: 2px;
}
.padding-bottom-5 {
  padding-bottom: 50px;
}
.inline-group {
  display: inline-block;
}
.inline-span {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}
.width-100 {
  width: 100%;
}
.width-50 {
  width: 50%;
}
.width-30 {
  width: 30%;
}
.width-20 {
  width: 20%;
}
.size-60-fixed {
  width: 60px;
  height: 60px;
}
.full-size {
  width: 100%;
  vertical-align: middle;
  /* height: 50%; */
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.horizon-center {
  text-align: center;
}
.vertical-middle {
  vertical-align: middle;
}
.border-gray {
  border: 1px solid #e2e2e2;
}
.border-top-gray {
  border-top: 1px solid #e2e2e2;
}
.border-bottom-gray {
  border-bottom: 1px solid #e2e2e2;
}
.opacity-0 {
  opacity: 0;
}
.overflow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.height-card {
  height: 98%;
}
.height-50 {
  height: 50%;
}
.height-25 {
  height: 25%;
}
.background-gray{
  background-color: lightgray;
}
.padding-custom-location {
  padding: 0 30px;
}
.padding-custom {
  padding: 0 10px;
}
.padding-left-0 {
  padding-left: 0;
}
.top-margin-custom {
  margin-top: 10px;
}
.custom-map-content {
  width: 100%;
  height: 350px;
}
.margin-0 {
  margin: 0;
}
.padding-10 {
  padding: 10px;
}
.bottom-margin-20 {
  margin-bottom: 20px;
}
.fs-20 {
  font-size: 20px;
}
.fs-15 {
  font-size: 15px;
}
.fixed-div {
  position: absolute;
  overflow: hidden;
}
.custom-footer-1 {
  position: absolute;
  bottom: 0;
  padding: 0;
}
.custom-footer-2 {
  position: absolute;
  bottom: 60px;
  padding: 0;
}
.custom-footer-3 {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0;
}
.custom-footer-4 {
  position: absolute;
  bottom: 60px;
  left: 0;
  padding: 0;
}
.opacity-top {
  opacity: 9999;
}
.custom-close-section {
  height: 77px;
  margin-left: 14px;
  padding: 2px 4px 2px 2px;
}
.print-image-div {
  height: 90px;
  background: no-repeat center;
  background-size: contain;
}
.dispenser-card {
  height: 996px;
}

.flex-horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-vertical {
  display: flex;
  flex-direction: column;
}

.flex-item {
  flex: 1;
}

.flex-end {
  align-content: flex-end;
}

.flex-horizontal label {
  margin-bottom: 0;
  margin-right: 8px;
}

.DateRangePicker {
  float: right;
}
